<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.sync_config") }}

      <v-spacer></v-spacer>
      <v-btn color="green darken-1" class="mr-2" outlined @click="save">
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="red darken-1" outlined @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
    </v-card-title>

    <div v-if="configItem.auto_synchronize_stock" class="mb-3">
      <v-card-text class="font-weight-medium error--text pb-1">
        {{ $t("labels.sync_stock") }}
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model.number="configItem.synchronize_stock_percent"
              class="c-input-xs"
              type="text"
              :label="$t('labels.quantity_percent')"
              :placeholder="$t('labels.quantity_percent_by_available')"
              dense
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              class="c-input-xs"
              v-model.number="configItem.synchronize_stock_type"
              :items="syncStockTypeOptions"
              :label="$t('labels.frequency_time')"
              :placeholder="$t('labels.frequency_by_time')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <TimePicker
              :label="$t('labels.start_time')"
              :placeholder="$t('labels.start_time')"
              :default-value="configItem.synchronize_stock_start_time"
              @onChange="configTimeChanged"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="configItem.auto_synchronize_order">
      <v-card-text class="font-weight-medium error--text pb-1">
        {{ $t("labels.sync_order") }}
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-autocomplete
              class="c-input-xs"
              v-model.number="configItem.synchronize_order_type"
              :items="syncOrderTypeOptions"
              :label="$t('labels.order_confirm_time_after_create')"
              :placeholder="$t('labels.frequency_by_time')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              class="c-input-xs"
              v-model.number="configItem.is_dropoff"
              :items="isDropoffOptions"
              :label="$t('labels.pickup_dropoff')"
              :placeholder="$t('labels.pickup_dropoff')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="font-weight-medium error--text pb-1 d-flex">
        {{ $t("labels.auto_synchronize_order_disable_time") }}
        <v-spacer></v-spacer>
        <v-btn x-small color="primary" @click="addDisableTime">
          {{ $t("labels.add") }}
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <tbody>
              <tr v-for="(disableTime, key) in disableTimes" :key="`d_${key}`">
                <td>
                  <time-picker-dialog
                    v-model="disableTime.from"
                    :label="$t('labels.from')"
                    :placeholder="$t('labels.from')"
                    :single-line="false"
                  ></time-picker-dialog>
                </td>
                <td>
                  <time-picker-dialog
                    v-model="disableTime.to"
                    :label="$t('labels.to')"
                    :placeholder="$t('labels.to')"
                    :single-line="false"
                  ></time-picker-dialog>
                </td>
                <td
                  style="min-width: 60px !important; width: 60px !important"
                  class="text-right"
                >
                  <v-btn
                    x-small
                    color="error"
                    @click="removeDisableTime(key)"
                    style="width: 50px"
                  >
                    {{ $t("labels.delete") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "EMarketShopSyncConfig",
  components: {
    TimePicker: () => import("@/components/common/TimePicker"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    configItem: {},
    syncStockTypeOptions: [],
    syncOrderTypeOptions: [],
    isDropoffOptions: [],
    disableTimes: [],
  }),
  created() {
    this.configItem = { ...this.item };
    this.disableTimes =
      this.configItem && this.configItem.auto_synchronize_order_disable_time
        ? JSON.parse(this.configItem.auto_synchronize_order_disable_time)
        : [];
    const syncStockTypeOptions = [
      {
        text: this.$t("labels.realtime"),
        value: 0,
      },
      {
        text: this.$t("labels.hour_time", { hour: "01" }),
        value: 1,
      },
      {
        text: this.$t("labels.hour_time", { hour: "02" }),
        value: 2,
      },
      {
        text: this.$t("labels.hour_time", { hour: "03" }),
        value: 3,
      },
      {
        text: this.$t("labels.hour_time", { hour: "04" }),
        value: 4,
      },
      {
        text: this.$t("labels.hour_time", { hour: "06" }),
        value: 6,
      },
      {
        text: this.$t("labels.hour_time", { hour: "08" }),
        value: 8,
      },
      {
        text: this.$t("labels.hour_time", { hour: "12" }),
        value: 12,
      },
      {
        text: this.$t("labels.hour_time", { hour: "24" }),
        value: 24,
      },
    ];
    const syncOrderTypeOptions = [
      {
        text: this.$t("labels.realtime"),
        value: 0,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "01h" }),
        value: 1,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "02h" }),
        value: 2,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "03h" }),
        value: 3,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "04h" }),
        value: 4,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "05h" }),
        value: 5,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "06h" }),
        value: 6,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "07h" }),
        value: 7,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "08h" }),
        value: 8,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "09h" }),
        value: 9,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "10h" }),
        value: 10,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "11h" }),
        value: 11,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "12h" }),
        value: 12,
      },
    ];
    const yesNoOptions = [
      {
        text: this.$t("labels.yes"),
        value: 1,
      },
      {
        text: this.$t("labels.no"),
        value: 0,
      },
    ];
    this.syncStockTypeOptions = [...syncStockTypeOptions];
    this.syncOrderTypeOptions = [...syncOrderTypeOptions];
    this.isDropoffOptions = [...yesNoOptions];
  },
  mounted() {},
  methods: {
    addDisableTime() {
      const item = {
        from: "00:00",
        to: "07:00",
      };
      this.disableTimes.unshift(item);
    },
    removeDisableTime(key) {
      this.disableTimes = [...this.disableTimes].filter(
        (item, index) => index != key
      );
    },
    configTimeChanged(time) {
      this.configItem = {
        ...this.configItem,
        synchronize_stock_start_time: time,
      };
    },
    close() {
      this.$emit("close", true);
    },
    save() {
      if (this.disableTimes && this.disableTimes.length > 0) {
        const checkErrorTime = [...this.disableTimes].filter(
          (dt) => dt.from > dt.to
        );
        if (checkErrorTime && checkErrorTime.length > 0) {
          this.$vToastify.error(this.$t("messages.invalid_period"));
          return false;
        }
      }
      this.$emit("changeConfig", {
        ...this.configItem,
        auto_synchronize_order_disable_time: JSON.stringify(this.disableTimes),
      });
    },
  },
};
</script>

<style scoped></style>
